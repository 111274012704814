import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`안녕하세요. 하조은입니다. 이 블로그는 제가 소프트웨어를 개발하며 배우고 느낀 점을 기록하는 공간입니다. 저에 대해 궁금하시다면 `}<a parentName="p" {...{
        "href": "/about"
      }}>{`소개 페이지`}</a>{`를 둘러보세요.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      